var $ = window.$;
function Index() {};

Index.prototype.init = function() {
    this.addResizeIntroHandlers();
	this.addMenuScrollHandlers();
	this.addContinueHandlers();
	this.initaliazeTiltContainers();
	this.addScrollReveal();
};

Index.prototype.addResizeIntroHandlers = function() {
    var $introWrapper = $('#intro-wrapper');

    function resize() {
        $introWrapper.width($(window).width());
        $introWrapper.height($(window).height());
    };

    $(window).resize(function() {
        resize();
    });

    // Resize on load
    resize();
};

Index.prototype.addMenuScrollHandlers = function() {

    // Listen to the on scroll event.
	$(window).scroll(function(e) {
		var scrollOffset = $(window).scrollTop() || 0;
		var topBar = $('.top-bar');
		var trigger = topBar.height() / 2;

		// Scroll offset condition.
		if(scrollOffset > trigger) {
			if (!topBar.hasClass('hidden-up')) {
				topBar.addClass('hidden-up');
			}
		} else {
			if (topBar.hasClass('hidden-up')) {
				topBar.removeClass('hidden-up');
			}
		}
	});

    $('.top-bar li:nth-of-type(2) a').click(function() {
		$(window).scrollTo('#no-problem-software', { duration: 1000 });
	});

    $('.top-bar li:nth-of-type(3) a').click(function() {
		$(window).scrollTo('#contact', { duration: 1000 });
        $('#contact form input').focus();
	});
};

Index.prototype.addContinueHandlers = function() {
	$('#continue').click(function() {
		$(window).scrollTo('#about-me', { duration: 1000 });
	});
};

Index.prototype.initaliazeTiltContainers = function() {
	var options = {
		movement: {
			imgWrapper : {
				rotation : {x: -5, y: 10, z: 0},
				reverseAnimation : {duration : 900, easing : 'easeOutCubic'}
			},
			caption : {
				translation : {x: 30, y: 30, z: [0,40]},
				rotation : {x: [0,15], y: 0, z: 0},
				reverseAnimation : {duration : 1200, easing : 'easeOutExpo'}
			},
			overlay : {
				translation : {x: 10, y: 10, z: [0,20]},
				reverseAnimation : {duration : 1000, easing : 'easeOutExpo'}
			},
			shine : {
				translation : {x: 100, y: 100, z: 0},
				reverseAnimation : {duration : 900, easing : 'easeOutCubic'}
			},
			lines : {
				translation : {x: 10, y: 10, z: [0,70]},
				rotation : {x: 0, y: 0, z: -2},
				reverseAnimation : {duration : 2000, easing : 'easeOutExpo'}
			},
		}
	}
	var tilters = document.getElementsByClassName('tilter');
	for(var i=0; i<tilters.length; i++) {
		new window.TiltFx(tilters[i], options);
	}
};

Index.prototype.addScrollReveal = function() {
	window.sr = window.ScrollReveal();
    window.sr.reveal('.tilter', { delay: 300 });
    window.sr.reveal('.images img', { delay: 300 });
    window.sr.reveal('.icons img', { delay: 300 });
	window.sr.reveal('#intro p', { delay: 500 });
};
