
window.Router = function() {

    // Route specific components.
    var mapping = {
        '/': ['Index', 'Contact'],
        '/project': ['Project']
    };

    var pathname = window.location.pathname;

    Object.keys(mapping).forEach(function(key) {
        if(pathname.indexOf(key) !== -1) {
            var classNames = mapping[key] || [];
            if(classNames instanceof Array) {
                classNames.forEach(function(className) {
                    var instance = new this[className]();
                    window.app.register(instance);
                });
            }
        }
    });

    // Global Components
    var globalModules = [];
    globalModules.forEach(function(className) {
        var instance = new this[className]();
        window.app.register(instance);
    });
}
