var $ = window.$;

function Contact() {};

Contact.prototype.init = function() {
    $('#contact-form').on('submit', function(e) {

        var form = $(this),
            submitButton = form.find('button');

        e.preventDefault();

        // Serialize the data from the form.
        var json = {};
        form.serializeArray().forEach(function(data) {
            json[data.name] = data.value;
        });

        // Update the ui.
        submitButton.attr('disabled', 'disabled');

        // Make the http request.
        $.post('/contact', json, function(response) {
            submitButton.removeAttr('disabled');
            form.find('#success').fadeIn();
            form.find('#success').removeClass('hide');
            document.getElementById('contact-form').reset();
        });
    });
}
