var $ = window.$;
/*
	App.
 */
function App() {

	// Data store for modules.
	this.modules = [];

	// Foundation.
	$(document).foundation();
}

App.prototype.init = function() {

	// Init module.
	this.extendJQuery();
	this.initTopBar();

	// Setup router that will add all the external modules.
	this.router = new Router();

	// Init external modules.
	this.modules.forEach(function(module) {
		console.info('initializing module: ', module);
		if (module.init instanceof Function) {
			module.init();	
		} else {
			console.warn('module does not have init function. Not initializing.', module);
		}
	});
}

/**
 * Register a module with the HLTH app. 
 * The app will call the init method once the document has loaded.
 * @param  {[Function instance]} module [The module that needs to be registered]
 */
App.prototype.register = function(module) {
	this.modules.push(module);
}

/**
 * Add scroll event handlers to the top bar that add the class `has-background` 
 * when the user scroll down more than 100px. If the top bar already has
 * this class, it will be removed when the user scrolls back up.
 */
App.prototype.initTopBar = function() {

	// Listen to the on scroll event.
	$(window).scroll(function(e) {
		var scrollOffset = $(window).scrollTop() || 0;
		var topBar = $('.top-bar');

		// Scroll offset condition.
		if(scrollOffset > 100) {
			if (!topBar.hasClass('has-background')) {
				topBar.addClass('has-background');
			}
		} else {
			if (topBar.hasClass('has-background')) {
				topBar.removeClass('has-background');
			}
		}
	});
}

/**
 * Adds convenience methods to the jQuery library.
 * For further info, refer to the documentation for each method inside the function body.
 */
App.prototype.extendJQuery = function() {

	/**
	 * See: http://stackoverflow.com/a/1186309/1054926
	 * This method can be used to serialize form data into a (PO) JSON Object.
	 */
	$.fn.serializeObject = function() {
	    var o = {};
	    var a = this.serializeArray();
	    $.each(a, function() {
	        if (o[this.name] !== undefined) {
	            if (!o[this.name].push) {
	                o[this.name] = [o[this.name]];
	            }
	            o[this.name].push(this.value || '');
	        } else {
	            o[this.name] = this.value || '';
	        }
	    });
	    return o;
	};

}

// Initialize a new app instance and attach it to the window.
window.app = new App();

/**
	Init app on document ready.
*/
jQuery(document).ready(function($) {
	window.app.init();
});